<template>
  <div class="calculate-chat-list">
    <div class="table-responsive thin-scrollbar" v-if="!state.chat.loaded || state.chat.list.length" :class="{skeleton: !state.chat.loaded}">
      <table class="table">
        <thead>
        <tr>
          <td>
            <span>프로젝트 번호</span>
          </td>
          <td>
            <span>프로젝트 이름</span>
          </td>
          <td>
            <span>최신 대화 내용</span>
          </td>
          <td>
            <span>작성자</span>
          </td>
          <td>
            <span>작성일</span>
          </td>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(i, idx) in state.chat.list" :key="idx">
          <td>
            <span class="dot" :class="{pending: i.adminChatRead === 'N'}"></span>
            <span>{{ i.projectSeq }}</span>
          </td>
          <td class="max-width">
            <Anchor :href="`/manage/reward/${i.projectSeq}/calculateNew`" target="_blank" rel="noopener noreferrer" :title="i.projectName">
              <span>{{ i.projectName }}</span>
            </Anchor>
          </td>
          <td class="max-width">
            <span :title="i.content">{{ i.content }}</span>
          </td>
          <td>
            <span>{{ i.memberName }}</span>
          </td>
          <td>
            <span>{{ i.updateDate }}</span>
          </td>
        </tr>
        </tbody>
      </table>
      <PaginationJpa className="btn-primary" :change="load" :total="state.total" :pageable="state.pageable" v-if="state.pageable.size"/>
    </div>
    <NoData v-else/>
  </div>
</template>

<script>
import mixin from "@/scripts/mixin";
import {defineComponent, reactive} from "@vue/composition-api";
import Anchor from "@/components/Anchor";
import http from "@/scripts/http";
import PaginationJpa from "@/components/PaginationJpa";
import NoData from "@/components/NoData";

function Component(initialize) {
  this.name = "pageAdminBoardCalculateChatList";
  this.initialize = initialize;
}

export default defineComponent({
  components: {NoData, PaginationJpa, Anchor},
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
      load();
    });

    const state = reactive({
      chat: {
        loaded: false,
        list: [],
      },
      args: {
        page: 0,
        size: 30,
      },
      pageable: {
        page: 0,
        size: 0,
      },
      total: 0,
    });

    const load = (page) => {
      state.chat.loaded = false;
      state.args.page = page || 0;

      for (let i = 0; i < 5; i += 1) {
        state.chat.list.push({
          projectSeq: "00000",
          projectName: "Wait a moment",
          content: "Please wait a moment",
          memberName: "Wait",
          updateDate: "0000-00-00 00:00:00"
        });
      }

      http.get("/api/admin/calculate/projects", state.args).then(({data}) => {
        state.chat.loaded = true;
        state.chat.list = data.body.content;
        state.pageable = data.body.pageable;
        state.total = data.body.total;
      });
    };

    return {component, state, load};
  }
});
</script>

<style lang="scss" scoped>
.calculate-chat-list {
  .table-responsive {
    table {
      thead {
        background-color: $colorBackground;
      }

      tbody {
        tr {
          transition: 0.18s;

          td {
            a {
              &:hover span {
                text-decoration: underline;
              }
            }

            span {
              vertical-align: middle;

              &.dot {
                border-radius: 50%;
                display: inline-block;
                width: $px5;
                height: $px5;
                vertical-align: middle;
                margin-right: $px5;

                &.pending {
                  background-color: #f5365c;
                }
              }
            }

            &.max-width {
              max-width: $px200;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }

          &:hover {
            background-color: $colorBackground;
          }
        }
      }
    }

    .pagination {
      margin-top: $px30;
    }

    &::-webkit-scrollbar {
      height: $px3;
    }

    &.skeleton {
      table {
        thead tr, tbody tr {
          td, th {
            text-overflow: initial;

            span {
              @include skeleton;
            }
          }
        }
      }
    }
  }

  .no-data {
    margin: $px50 0;
  }
}
</style>